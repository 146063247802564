<template>
  <div>
    <li v-if="isVisible" class="menu-item">
      <router-link
        v-if="!isAncestor"
        exact-active-class="text-success"
        :to="{ name: route.name }"
        class="menu-link"
      >
        <div>{{ name }}</div>
      </router-link>
      <a v-else class="menu-link">
        <div>{{ name }}</div>
      </a>
      <ul
        v-if="route.children && route.children.length"
        class="sub-menu-container"
      >
        <MenuItem
          v-for="(r, i) in route.children"
          :key="i"
          :route="r"
        ></MenuItem>
      </ul>
    </li>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    route: {
      type: Object,
    },
  },
  computed: {
    isVisible() {
      if (
        this.route.meta &&
        (this.route.meta.visible === undefined || this.route.meta.visible)
      ) {
        return true;
      }
      return false;
    },
    isAncestor() {
      if (this.route.meta && this.route.meta.isChildrenAncestor) {
        return true;
      }
      return false;
    },
    name() {
      return this.route.name
        .toLowerCase()
        .split("-")
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(" ");
    },
  },
};
</script>

<style>
.v-application ul.sub-menu-container {
  padding-left: 0;
}
</style>
